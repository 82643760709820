import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as IconClose } from 'resources/icons/close.svg';
import { Button, IconButton, SvgIcon, Typography } from '@mui/material';
import theme, { redesignColors } from 'resources/theme/theme.colors';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ResultsForm from './ResultsForm';
import { FilterResultsDate, FilterResultsFormValues, ResultNew } from 'models/result.model';
import { useEffect, useMemo } from 'react';
import { OfficeMedicalStaff, PatientTag } from 'models/record.model';

type FilterDrawerProps = {
  open: boolean;
  onApply: (values: FilterResultsFormValues) => void;
  onClear: () => void;
  onClose: () => void;
  doctors: OfficeMedicalStaff[];
  tags: PatientTag[];
  defaultFilters: FilterResultsFormValues;
  results: ResultNew[];
};

export default function ResultsFilterDrawer({ open, onClose, onApply, onClear, defaultFilters, doctors: propsDoctors, tags: propsTags }: FilterDrawerProps) {
  const { t } = useTranslation();

  const { register, watch, getValues, control, setValue, reset } = useForm<FilterResultsFormValues>({
    defaultValues: defaultFilters,
  });

  const { tags, doctors, date, datesRangeFrom, datesRangeTo } = watch();

  useEffect(() => {
    reset(defaultFilters);
  }, [defaultFilters]);

  const submitDisabled = useMemo(() => {
    const isDateRangeSet = date === FilterResultsDate.datesRange ? datesRangeFrom || datesRangeTo : date;
    const submitEnabled = tags.length || doctors.length || isDateRangeSet;
    return !submitEnabled;
  }, [tags, doctors, date, datesRangeFrom, datesRangeTo]);

  return (
    <Drawer anchor='right' open={open} onClose={onClose} sx={{ zIndex: 1300 }} PaperProps={{ sx: { width: 464 } }}>
      <Box
        display='flex'
        alignItems='center'
        p={2}
        sx={{ width: '100%', height: '56px', borderBottom: `1px solid ${redesignColors.grey.grey4}`, position: 'absolute', top: 0, left: 0, zIndex: 10 }}
        bgcolor={theme.palette.common.white}
      >
        <IconButton onClick={onClose}>
          <SvgIcon sx={{ fontSize: '16px' }} color='secondary' component={IconClose} />
        </IconButton>
        <Typography pl={2} variant='h2'>
          {t('centers.drawer.title')}
        </Typography>
      </Box>
      <Box pt={9} px={3} pb={11} sx={{ overflowX: 'auto', position: 'relative' }}>
        <ResultsForm doctors={propsDoctors} tags={propsTags} register={register} watch={watch} control={control} defaultValues={defaultFilters} setValue={setValue} />
      </Box>
      <Box
        display='flex'
        sx={{ borderTop: `1px solid ${redesignColors.grey.grey4}`, position: 'absolute', bottom: 0, left: 0, width: '100%', zIndex: 10 }}
        bgcolor={theme.palette.common.white}
        p={2}
        alignItems='center'
        justifyContent='space-between'
      >
        <Button
          variant='outlined'
          sx={{ width: '212px', height: '44px' }}
          onClick={() => {
            onClear();
            reset(defaultFilters);
          }}
        >
          {t('common.action.clearFilter')}
        </Button>
        <Button variant='contained' sx={{ width: '212px', height: '44px' }} disabled={!!submitDisabled} onClick={() => onApply(getValues())}>
          {t('common.action.apply')}
        </Button>
      </Box>
    </Drawer>
  );
}
