import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import api from 'api';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import MissingData from 'components/missing-data';
import ContextLink from 'components/_base/ContextLink';
import PatientDetails from 'components/patient-details';
import { useTranslation } from 'react-i18next';
import { Patient } from 'models/patients.model';
import { Selection } from 'models/record.model';
import { ReactComponent as SortIcon } from 'resources/icons/sort.svg';
import { FilterResultsDate, FilterResultsFormValues, Result, ResultGraph, ResultHistory, ResultNew, ResultObject } from 'models/result.model';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { convertDate } from 'utils/func/Date.func';
import GraphHistory from './graph';
import { formatToLocaleNumber } from 'utils/func/Number.func';
import { redesignColors } from 'resources/theme/theme.colors';
import { defaultValues, getFilterDateValues } from 'pages/patient/results/utils/filters';
import HistoryResultsFilterDrawer from '../components/HistoryResultsFilterDrawer';

interface CustomizedState {
  patient: Patient;
  selection: Selection[];
}

const History: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<FilterResultsFormValues | null>(null);

  const location = useLocation();
  const { patient, selection } = location.state as CustomizedState;
  const referencesArray: string[] = useMemo(() => [], []);
  const [historyDataValues, setHistoryDataValues] = useState<ResultHistory[]>([]);
  const { t } = useTranslation();
  const historyDataValuesArray: ResultGraph[] = [];

  useEffect(() => {
    const { date, datesRangeFrom, datesRangeTo } = filterData || defaultValues;

    selection.map((el: { ref: string; name: string }) => referencesArray.push(el.ref));
    api.results.getHistoryFormResults(patient.id, referencesArray).then((response: ResultHistory[]) => {
      const filtered = response.map((item) => {
        const history = Object.values(item.history).reduce(
          (acc, cur) => {
            const dateRangeValues = date && getFilterDateValues(date, datesRangeFrom, datesRangeTo);

            if (date == FilterResultsDate.all) {
              return { ...acc, [cur.sampling_time]: cur };
            }
            if (dateRangeValues?.minDate && dateRangeValues?.maxDate) {
              const maxDateTimestamp = new Date(dateRangeValues?.maxDate).getTime();
              const minDateTimestamp = new Date(dateRangeValues?.minDate).getTime();
              const samplingTimeTimestamp = new Date(cur.sampling_time).getTime();
              if (samplingTimeTimestamp <= maxDateTimestamp && samplingTimeTimestamp >= minDateTimestamp) {
                return { ...acc, [cur.sampling_time]: cur };
              }
            }
            if (dateRangeValues?.minDate && !dateRangeValues?.maxDate) {
              const minDateTimestamp = new Date(dateRangeValues?.minDate).getTime();
              const samplingTimeTimestamp = new Date(cur.sampling_time).getTime();
              if (samplingTimeTimestamp >= minDateTimestamp) {
                return { ...acc, [cur.sampling_time]: cur };
              }
            }
            return acc;
          },
          {} as Record<string, Result>,
        );
        return { ...item, history };
      });

      setHistoryDataValues(filtered);
    });
  }, [patient.id, referencesArray, selection, filterData, defaultValues]);

  historyDataValues.forEach((el: ResultObject, key: number) => {
    const historyArray: Result[] = [];
    Object.keys(el.history).forEach((el2, key2) => {
      historyArray.push(el.history[el2]);
    });
    if (!historyArray.length) {
      return;
    }
    historyDataValuesArray.push({
      name: historyArray[0].name,
      reference: el.reference,
      history: historyArray.filter((value) => value.sampling_time !== null),
    });
  });
  const { date, datesRangeFrom, datesRangeTo } = filterData || defaultValues;
  return (
    <BodyTemplate title={t('bodyTemplate.doctor.resultsHistory')} backButton>
      <Grid container>
        <Grid item xs={12}>
          <PatientDetails showPatientRecordAction patient={patient} />
        </Grid>
        {historyDataValuesArray?.length && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <GraphHistory historyDataValues={historyDataValuesArray} />
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant='h2' mb={2}>
                {t('common.results')}
              </Typography>
              <Button
                variant='outlined'
                sx={{ mb: 2, fontSize: '14px', position: 'relative', border: `1px solid ${redesignColors.grey.grey4}` }}
                startIcon={<SvgIcon component={SortIcon} sx={{ color: redesignColors.blue.main }} />}
                onClick={() => setOpen(true)}
              >
                {t('result_doctor.history.sort_by_date')}:{' '}
                {date === FilterResultsDate.datesRange
                  ? `${convertDate(datesRangeFrom, false, 'yyyy-MM-dd')} - ${convertDate(datesRangeTo, false, 'yyyy-MM-dd')}`
                  : t(`result_doctor.filter_drawer.${filterData?.date || defaultValues.date}`)}
              </Button>
              {historyDataValuesArray?.length ? (
                historyDataValuesArray.map((el: ResultGraph, key: number) => {
                  return (
                    <Accordion defaultExpanded={true} key={key} sx={{ marginBottom: 2, '&::before': { height: 0 } }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                          '&[aria-expanded="true"]': { background: redesignColors.blue.main },
                          '&[aria-expanded="false"]': { borderBottom: `2px solid ${redesignColors.blue.main}` },
                          transition: '0.3s all',
                          px: 2,
                          borderRadius: '8px 8px 0 0',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography variant='h3'>{el.history[0] ? el.history[0].name : <MissingData />}</Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails sx={{ margin: 0, borderRadius: '0 0 8px 8px', border: `1px solid ${redesignColors.grey.grey4}` }}>
                        <TableContainer>
                          <Table>
                            <TableHead sx={{ background: redesignColors.blue.blue1 }}>
                              <TableRow>
                                <TableCell>
                                  <Typography variant='body1'>{t('common.table.tableHead.sample_date')}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='body1'>{t('common.table.tableHead.value')}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant='body1'>{t('common.table.tableHead.usualValues')}</Typography>
                                </TableCell>
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(el.history).map((e, key) => {
                                return (
                                  <TableRow key={key} sx={{ '& p, & span': { fontWeight: '500' } }}>
                                    <TableCell>
                                      <Typography variant='body1'>{convertDate(el.history[parseInt(e)].sampling_time, false, 'dd/MM/yyyy hh:mm')}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      {el.history[parseInt(e)].value !== null || el.history[parseInt(e)].unit !== null ? (
                                        el.history[parseInt(e)].is_abnormal ? (
                                          <Typography variant='body2' sx={{ color: 'red' }}>
                                            <strong>{el.history[parseInt(e)].value + ' ' + el.history[parseInt(e)].unit}</strong>
                                          </Typography>
                                        ) : (
                                          <Typography variant='body2'>{formatToLocaleNumber(el.history[parseInt(e)].value) + ' ' + el.history[parseInt(e)].unit}</Typography>
                                        )
                                      ) : (
                                        '-'
                                      )}
                                    </TableCell>
                                    <TableCell>
                                      <Typography variant='caption'>
                                        {el.history[parseInt(e)].limit_max !== null || el.history[parseInt(e)].limit_min !== null || el.history[parseInt(e)].unit !== null
                                          ? formatToLocaleNumber(el.history[parseInt(e)].limit_min) + ' - ' + formatToLocaleNumber(el.history[parseInt(e)].limit_max) + el.history[parseInt(e)].unit
                                          : '-'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>
                                      <ContextLink
                                        to='DETAIL_RESULT'
                                        params={{
                                          id: el.history[parseInt(e)].dossier_reference,
                                        }}
                                      >
                                        <Button variant='header_light'>
                                          {t('result.doctor.resultsHistory.action.file', {
                                            replace: {
                                              date:
                                                convertDate(el.history[parseInt(e)].sampling_time, false, 'dd/MM/yyyy ') !== '-'
                                                  ? convertDate(el.history[parseInt(e)].sampling_time, false, 'dd/MM/yyyy ')
                                                  : convertDate(new Date(), false, 'dd/MM/yyyy '),
                                            },
                                            interpolation: {
                                              escapeValue: false,
                                            },
                                          })}
                                        </Button>
                                      </ContextLink>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <Typography variant='body1' fontWeight='500' mt={2}>
                  {t('result_doctor.history.no_history_results')}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <HistoryResultsFilterDrawer open={open} onClose={() => setOpen(false)} onApply={(data) => setFilterData(data)} defaultFilters={filterData || defaultValues} onClear={() => setFilterData(null)} />
    </BodyTemplate>
  );
};

export default History;
