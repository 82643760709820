import { HistoryValues } from 'models/graph.model';
import { FC } from 'react';
import dayjs from 'dayjs';
import { redesignColors } from 'resources/theme/theme.colors';
import { Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Area, DotProps } from 'recharts';

const CustomizedDot = (props: any) => {
  const { cx, cy, payload, value } = props;
  const up = payload?.up;
  const fill = value < up[0] || value > up[1] ? redesignColors.red : redesignColors.green.common;

  return <circle cx={cx} cy={cy} r={3} stroke='none' fill={fill} />;
};

const GraphHistory: FC<HistoryValues> = ({ historyDataValues }) => {
  const firstValue = historyDataValues[0];

  const data = firstValue.history
    .map((el) => ({
      uv: parseFloat(el.value),
      up: [parseFloat(el.limit_min), parseFloat(el.limit_max)],
      name: dayjs(el.sampling_time).year(),
    }))
    .sort((first, second) => first.name - second.name);
  const uniqueData = Array.from(new Map(data.map((item) => [item.name, item])).values());

  return (
    <ResponsiveContainer width={'100%'} height={300}>
      <ComposedChart data={data} margin={{ top: 25, left: -15 }} accessibilityLayer>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' ticks={uniqueData.map((item) => item.name)} stroke={redesignColors.grey.grey4} tick={{ fill: redesignColors.grey.grey2 }} />
        <YAxis stroke={redesignColors.grey.grey4} tick={{ fill: redesignColors.grey.grey2 }} label={{ value: firstValue.history[0].unit, position: 'top', offset: 12, fill: redesignColors.dark }} />
        <Tooltip formatter={(value) => [value, firstValue.name]} />
        <Area type='monotone' dataKey='up' stroke='none' fill={redesignColors.green.common} fillOpacity={0.2} connectNulls dot={false} activeDot={false} />
        <Line dataKey='uv' stroke='none' dot={CustomizedDot}></Line>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default GraphHistory;
